body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --amplify-primary-color: #1B4985;
  --amplify-primary-tint: #bac8da;
  --amplify-primary-shade: #153a6a;
  --amplify-primary-light: #f1f4f7;
  --amplify-secondary-color: #C6D0D0;
  --amplify-secondary-tint: #d7dede;
  --amplify-secondary-shade: #8A9191;
  --amplify-background-color: #f3f5f5;
  --cedar-csi-primary-tint: #da6254;
}

.amplify-button--primary {
  background: var(--amplify-primary-color) !important ;
}

/* SH 1/16 - Set Color of Login Button */
/* .amplify-button[data-variation='primary']:hover { */
  .amplify-button--primary:hover {
    background: var(--amplify-primary-shade) !important ;
  }

.auth-wrapper {
  background-color: var(--amplify-background-color);
}

.amplify-button[data-variation='primary'] {
  background: var(--amplify-primary-color);
}

.amplify-button[data-variation='primary']:hover {
  background: var(--amplify-primary-shade);
}

.MuiTypography-subtitle2 {
  color: var(--amplify-secondary-shade);
}

.MuiTableCell-root {
  padding: 10px;
}

.react-chatbot-kit-chat-btn-send {
  /* background-color: #1b7e85 !important ; */
  background-color: var(--cedar-csi-primary-tint)!important ;
}

.react-chatbot-kit-chat-bot-message {
  background-color: var(--amplify-primary-color) !important ;
  width: 259.5px !important ;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid var(--amplify-primary-color) !important ;
}

.react-chatbot-kit-user-chat-message {
  margin-right: 15.5px !important ;
}

.react-chatbot-kit-chat-input:focus {
    outline: none;
}

.react-chatbot-kit-chat-container {
    width: 350px !important ;
    height: 600px !important ;
}

.react-chatbot-kit-chat-inner-container {
    height: 600px !important ;
}

.react-chatbot-kit-chat-message-container {
    height: 499px !important ;
}

div.botSlots {
  padding: 15px;
  border-radius: 3px;
  background-color: #f1e6e6;
}


.bot-option-button {
  margin: 5px;
  border-radius: 25px;
  padding: 8px;
  border-color: var(--amplify-primary-color);
  background-color: var(--amplify-primary-light);
  text-align: center;
  border-width: thin;
}
